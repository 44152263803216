<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core'

const props = defineProps<Props>()
const NUMBER_COLORS_FOR_SMALL_SCREENS = 3
const NUMBER_COLORS_FOR_LARGE_SCREENS = 5

interface Props {
  products: {
    color: string
    id: string
  }[]
}

// small devices including tablet
const isSmallScreen = useMediaQuery('(max-width: 959px)')

const productsToShow = computed(() => {
  return props.products.slice(0, isSmallScreen.value ? NUMBER_COLORS_FOR_SMALL_SCREENS : NUMBER_COLORS_FOR_LARGE_SCREENS)
})

const numberColorsHidden = computed(() => {
  const totalColorsList = props.products.length
  return isSmallScreen.value ? Math.max(totalColorsList - NUMBER_COLORS_FOR_SMALL_SCREENS, 0) : Math.max(totalColorsList - NUMBER_COLORS_FOR_LARGE_SCREENS, 0)
})

function getColor(value: string) {
  return value.toLowerCase().split('-').join('') || 'black'
}
</script>

<template>
  <section class="color-indicator-list">
    <ProductColorIndicator v-for="product in productsToShow" :key="product" :color="getColor(product.color)" :sku="product.id" />
    <div v-if="numberColorsHidden > 0" class="colors-hidden-label">
      +{{ numberColorsHidden }}
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.color-indicator-list {
  gap: 0.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  line-height: 1.71;
  align-items: center;
  height: 2.5rem;
}

.colors-hidden-label {
  @include body2;
  align-self: flex-end;
  height: 2.4rem;
}
</style>
