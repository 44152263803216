<script setup lang="ts">
interface Props {
  repeat?: number
  size?: 'small' | 'large'
}

const props = withDefaults(defineProps<Props>(), {
  repeat: 5,
  size: 'small',
})

const { $t } = useNuxtApp()

const space = computed(() => (props.size === 'small' ? ' ' : ' '.repeat(7)))
const text = computed(() => ($t('soldOut') + space.value).repeat(props.repeat))
</script>

<template>
  <div class="indicator-sold-out" :class="[size]">
    <span :data-text="text" :aria-label="$t('soldOut')" />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.indicator-sold-out {
  white-space: pre;
  font-size: 1.6rem;
  text-align: center;
  padding: 1rem 3rem;
  background: #fff;
}

span {
  display: inline-block;
}
span::after {
  content: attr(data-text);
}

.small span {
  margin-right: clamp(0.5rem, 10vw, 1rem);
}
.large span {
  margin-right: clamp(1rem, 10vw, 4rem);
}

@media screen and (min-width: $tablet) {
  .indicator-sold-out.large {
    font-size: 2.1rem;
  }
}
</style>
