<script setup lang="ts">
import type { ProductBadge } from 'types/models/product'

interface Props {
  badge: ProductBadge
}

const props = defineProps<Props>()

const { $t } = useNuxtApp()

const badgeParts = computed(() => {
  const text = $t(`badge-hero-${props.badge.name}`)
  return text.split('<br>')
})
</script>

<template>
  <div v-bind="$attrs" class="hero-badge">
    <span v-for="(badgePart, index) in badgeParts" :key="index" class="hero-text">{{ badgePart }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.hero-badge {
  @include heroLabel;
  font-size: 1.5rem;
	position: absolute;
  transform-origin: top right;
  transform: rotate(-20deg);

  @media (min-width: $phone) {
    font-size: 2rem;
  }
}

.hero-text {
  display: block;
}
</style>
